import React from "react";
import '../Home-css/WorkExperience.css'

const WorkExperience = React.forwardRef((props, ref) => {
  return (
    <div className="work-experience-section">
      {/* Work Experience Section */}
      <div className="work-experience" ref={ref}>
        <h2>Work Experience</h2>
        <p>
        I focused on creating user-friendly interfaces that were both scalable and interactive. 
        As part of my final year project, I worked on a decentralized recruitment platform that
         utilized blockchain technology, including Ethereum, Hardhat, and Ether.js. I integrated
          Firebase for real-time database management and created engaging Lottie animations using 
          Adobe After Effects to enhance the user experience. Additionally, I collaborated on UI/UX
           design using Figma, and contributed to 3D modeling and animation with Blender, 
        further developing my technical and creative skills.
        </p>
      </div>

      {/* Skills Section */}
      <div className="skills">
        <h3>Programming Languages</h3>
        <ul>
          <li>C++</li>
          <li>JavaScript</li>
          <li>Solidity</li>
        </ul>
      </div>

      {/* Libraries Section */}
      <div className="libraries">
        <h3>Libraries</h3>
        <ul>
          <li>React.js</li>
          <li>Ethers.js</li>
        </ul>
      </div>

      {/* Frameworks Section */}
      <div className="frameworks">
        <h3>Frameworks</h3>
        <ul>
          <li>Node.js</li>
          <li>Hardhat.js</li>
        </ul>
      </div>

      {/* Animation Tools Section */}
      <div className="animation-tools">
        <h3>Animation Tools</h3>
        <ul>
          <li>Adobe After Effects</li>
          <li>Adobe Premiere Pro</li>
          <li>Adobe Photoshop</li>
          <li>Adobe Audition</li>
          <li>Three JS (currently learning)</li>
          <li>Blender</li>
        </ul>
      </div>
    </div>
  );
});

export default WorkExperience;
