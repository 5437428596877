import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

// Assets png's
import fiverr from '../../assets/fiverr.webp';
import linkedin from '../../assets/linkein.webp';
import upwork from '../../assets/upwork.webp';
import whatsapp from '../../assets/whatsapp.webp';
import github from '../../assets/github.webp';

// Profile pic
import profile from '../../assets/pic.webp';

// Support pic
import fast from '../../assets/fast.webp';
import satis from '../../assets/satis.webp';
import support from '../../assets/support.webp';



import animationData from '../../assets/lotie_pic.json';

const HomeHerosec = () => {
  return (
    <div className="home-hero-section">
      {/* Upper Section */}
      <div className="upper-section">
        <div className="text-container">
          <h1>
            <span className="heading-highlight">Usman Sultan</span>
            <br />
            Animator, Web App Dev, Blockchain Dev.
          </h1>
          <p>
          Highly motivated and detail-oriented Computer Science graduate specializing in front-end development and modern web technologies. Proficient in React.js, JavaScript, HTML5, CSS3, Material UI, and Lottie file integration from Adobe After Effects, with a strong focus on creating responsive, interactive, and visually appealing web applications. Experienced in UI/UX design, prototyping, and delivering dynamic animations to enhance user engagement. Passionate about leveraging cutting-edge technologies to craft seamless and immersive user experiences while collaborating on innovative projects.
          </p>
          <div className="social-icons">
            <a
              href="https://www.upwork.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="icon upwork"
            >
              <img src={upwork} alt="Upwork" className="icon-image" />
            </a>
            <a
              href="https://www.fiverr.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="icon fiverr"
            >
              <img src={fiverr} alt="Fiverr" className="icon-image" />
            </a>
            <a
              href="https://www.linkedin.com/in/usman-khan-648ab0209/"
              target="_blank"
              rel="noopener noreferrer"
              className="icon linkedin"
            >
              <img src={linkedin} alt="LinkedIn" className="icon-image" />
            </a>
            <a
              href="https://github.com/usmansultan7"
              target="_blank"
              rel="noopener noreferrer"
              className="icon github"
            >
              <img src={github} alt="Github" className="icon-image" />
            </a>
            <a
              href="https://wa.me/923114620040?text=Hello!%20I'm%20interested%20in%20your%20services.%20Can%20we%20talk?"
              target="_blank"
              rel="noopener noreferrer"
              className="icon whatsapp"
            >
              <img src={whatsapp} alt="WhatsApp" className="icon-image" />
            </a>
          </div>
        </div>
        <div className="image-container">
          <Player
            autoplay
            loop
            src={animationData}
            className="lottie-background"
          />
          <img
            src={profile}
            alt="Visual Representation"
            className="profile-image"
          />
        </div>
      </div>

      {/* Lower Section */}
      <div className="lower-section">
        <div className="icon-section">
          <img src={fast} alt="Fast Delivery" className="icon-image" />
          <p>Strategic Planning</p>
        </div>
        <div className="icon-section">
          <img src={satis} alt="100% Satisfaction" className="icon-image" />
          <p>Team player</p>
        </div>
        <div className="icon-section">
          <img src={support} alt="24/7 Support" className="icon-image" />
          <p>Quick Response</p>
        </div>
      </div>
    </div>
  );
};

export default HomeHerosec;
